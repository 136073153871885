import api from './';

export async function findBackdropsByRecipient(email, orgId) {
  if (!email || email === '') {
    throw new Error('Email cannot be empty');
  }
  const resp = await api.service('contacts').find({
    query: { email, organization: orgId, $limit: 1000 },
  });
  if (!resp || resp.data?.length === 0) {
    return [null, []];
  }
  const contactIds = resp.data.map((contact) => contact._id);
  const resp2 = await api.service(`space-invites`).find({
    query: {
      contact: contactIds,
      $limit: 10000,
      $sort: { createdAt: -1 },
    },
  });
  if (!resp2 || resp2.data?.length === 0) {
    return [contactIds, []];
  }

  return [contactIds, resp2.data];
}

export async function fetchPlaybooks() {
  const query = { $limit: 10000, $sort: { createdAt: 1 } };
  const resp = await api.service('tags').find({ query });
  return resp?.data || [];
}

export function disableRecipientVerification(backdropCode) {
  return api.service('space-invites').patch(backdropCode, {
    disableRecipientVerification: true,
  });
}

export function enableRecipientVerification(backdropCode) {
  return api.service('space-invites').patch(backdropCode, {
    disableRecipientVerification: false,
  });
}

export async function updateBackdropTemplate(backdropCode, templateId) {
  return api.service('space-invites').patch(backdropCode, {
    template: templateId,
  });
}
