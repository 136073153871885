import get from 'lodash/get';

export function findAndReplaceMedia(collection, media) {
  const recordIndex = collection.findIndex((c) => c._id === media._id);
  if (recordIndex > -1) {
    let nextRecords = [...collection]; // clone
    const prevRecord = collection[recordIndex];
    const nextRecord = {
      ...media,
      organization: prevRecord.organization,
      createdBy: prevRecord.createdBy,
    };

    // update thumbnail status
    if (prevRecord.fileVersion < nextRecord.fileVersion) {
      nextRecord.updatingThumbnail =
        !nextRecord.thumbnailUrl || !nextRecord.thumbnailUrl.length;
    }

    nextRecords[recordIndex] = nextRecord;
    return nextRecords;
  }
  return collection;
}

export function findAndReplaceMediaInSpace(space, media) {
  const postIndex = space.posts.findIndex(
    (p) => get(p, 'media[0]._id') === media._id
  );
  if (postIndex > -1) {
    const post = {
      ...space.posts[postIndex],
      media: [media],
    };

    const nextSpace = { ...space };
    nextSpace.posts[postIndex] = post;

    return nextSpace;
  }

  return space;
}

export function findAndReplacePostInSpace(space, post) {
  const postIndex = space.posts.findIndex((p) => p._id === post._id);

  if (postIndex > -1) {
    const nextPost = {
      ...space.posts[postIndex],
      annotation: post.annotation,
      title: post.title,
      downloadable: post.downloadable,
      approvalRequired: post.approvalRequired,
      isApproved: post.isApproved,
    };

    const nextSpace = { ...space };
    nextSpace.posts[postIndex] = nextPost;

    return nextSpace;
  }

  return space;
}
